import React, {useEffect, useMemo, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import {Card, Dropdown, Row, Col} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import classNames from "classnames";
import FormInput from "../FormInput";
import {StoryCustomChatRequest, StoryCustomMessageModel} from "../../model/StoryCustomChat";
import {APICore} from "../../helpers/api/apiCore";
import {storyCustomizationContinuations} from "../../helpers/api/storyCustomizationConversation";




/* Chat Item Avatar */
const ChatItemAvatar = ({
                            userAvatar
                        }: {
    userAvatar: string;
}) => {
    return (
        <>
            <div className="chat-avatar">
                <img src={userAvatar} alt={userAvatar}/>
            </div>
        </>
    );
};

/* Chat Item Text */
const ChatItemText = ({
                          userName,
                          text,
                      }: {
    userName: string;
    text: string;
}) => {
    return (
        <>
            <div className="conversation-text">
                <div className="ctext-wrap">
                    <b style={{color: "black"}}>{userName}</b>
                    <p dangerouslySetInnerHTML={{ __html: text.replace(/"/g, '&quot;').replace(/\*/g, '&#42;').replace(/~/g, '<span style="font-style: italic;">').replace(/~/g, '</span>') }}></p>
                </div>
            </div>
        </>
    );
};

/* Chat Item */
const chatItemDefaultProps = {
    placement: "",
    children: PropTypes.object,
    className: "",
};

const ChatItem = ({
                      children,
                      placement,
                      className,
                  }: {
    children: any;
    placement: string;
    className: string;
}) => {
    return (
        <li
            className={classNames(
                "clearfix",
                {odd: placement === "left"},
                className
            )}
        >
            {children}
        </li>
    );
};

ChatItem.defaultProps = chatItemDefaultProps;

/**
 * ChatForm
 */

interface FormValues {
    newMessage: string;
}

/**
 * Renders the ChatForm
 */
const ChatForm = ({
                      onNewMessagesPosted,
                      onRegenNewMessagesPosted,
    disable
                  }: {
    onNewMessagesPosted: (message: string) => void;
    onRegenNewMessagesPosted: () => void;
    disable: boolean;
}) => {
    /*
     * form validation schema
     */
    const schemaResolver = yupResolver(
        yup.object().shape({
            newMessage: yup.string().required("Please enter your messsage"),
        })
    );

    const methods = useForm<FormValues>({resolver: schemaResolver});
    const {
        handleSubmit,
        register,
        control,
        formState: {errors},
        reset,
    } = methods;

    /**
     * Handle valid form submission
     */
    const handleValidMessageSubmit: SubmitHandler<FormValues> = (values) => {
        const message = values["newMessage"];
        onNewMessagesPosted(message);
        reset();
    };

    const handleRegenMessageSubmit = () => {
        onRegenNewMessagesPosted();
        reset();
    }

    return (
        <>
            <form
                name="chat-form"
                id="chat-form"
                onSubmit={handleSubmit(handleValidMessageSubmit)}
            >
                <Row>
                    <Col>
                        <FormInput
                            type="text"
                            name="newMessage"
                            className="form-control chat-input"
                            placeholder="Enter your text"
                            register={register}
                            key="newMessage"
                            errors={errors}
                            control={control}
                            disabled={disable}
                        />
                    </Col>
                    <Col className="col-auto">
                        <button
                            type="submit"
                            className="btn btn-danger chat-send waves-effect waves-light"
                            disabled={disable}
                        >
                            Send
                        </button>
                    </Col>
                    <Col className="col-auto">
                        <button
                            type="button"
                            className="btn btn-success chat-send waves-effect waves-light"
                            disabled={disable}
                            onClick={handleRegenMessageSubmit}
                        >
                            Regen
                        </button>
                    </Col>
                </Row>
            </form>
        </>
    );
};

/**
 * ChatList
 */


interface ChatListProps {
    className?: string;
    storyCustomization?: any;
    model?: string;
    setError?: any;

}

/**
 * Renders the ChatList
 */
const ChatCustomList = (props: ChatListProps) => {
    const [messages, setMessages] = useState<Array<StoryCustomMessageModel>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {storyCustomization, model, setError} = props;
    const api = useMemo(() => new APICore(), []);
    const user = api.getLoggedInUser();
    const userName = user.fullName;
    useEffect(() => {
        if (!storyCustomization) return;
        setMessages(storyCustomization.openTurns.map((turn: any) => {
            return {
                owner: turn.owner,
                content: turn.content,
                createdAt: new Date()
            }
        }));
    }, [storyCustomization]);

    /**
     * Handle new message posted
     */
    const handleNewMessagePosted = async (message: string) => {
        try {
            setIsLoading(true);
            setMessages(messages.concat({
                    owner: userName,
                    content: message,
                    createdAt: new Date()
                })
            );
            const chatRequest = {
                modelChat: model,
                user: {
                    id: user.id,
                    fullName: user.fullName
                },
                previousSummary: {
                    longTermSummary: '',
                    shortTermSummary: ''
                },
                messages:
                    messages.map((message) => {
                        return {
                            owner: message.owner,
                            content: message.content,
                            createdAt: message.createdAt
                        }
                    }).concat({
                        owner: userName,
                        content: message,
                        createdAt: new Date()

                    })

            }
            const response = await storyCustomizationContinuations(storyCustomization.id, chatRequest as StoryCustomChatRequest);
            if (response.status !== 200) {
                setError(response.data.message);
                return;
            }
            const messagesResponse = response.data.turns;
            if (!messagesResponse) {
                messages.pop();
                return;
            }
            messagesResponse.unshift({
                owner: userName,
                content: message,
                createdAt: new Date()
            });
            setMessages(messages.concat(messagesResponse));
        } catch (e) {
            setError(e as string);
        } finally {
            setIsLoading(false);
        }

    };

    const handleRegenNewMessagePosted = async () => {
        try {
            let index = messages.length - 1;
            while (index >= 0 && messages[index].owner !== userName) {
                index--;
            }
            const messagesClone = messages.slice(0, index + 1);
            setMessages(messagesClone);

            setIsLoading(true);
            const chatRequest = {
                modelChat: model,
                user: {
                    id: user.id,
                    fullName: user.fullName
                },
                previousSummary: {
                    longTermSummary: '',
                    shortTermSummary: ''
                },
                messages:
                    messagesClone.map((message) => {
                        return {
                            owner: message.owner,
                            content: message.content,
                            createdAt: message.createdAt
                        }
                    })

            }
            const response = await storyCustomizationContinuations(storyCustomization.id, chatRequest as StoryCustomChatRequest);
            if (response.status !== 200) {
                setError(response.data.message);
                return;
            }
            const messagesResponse = response.data.turns;
            if (!messagesResponse) {
                messages.pop();
                return;
            }
            setMessages(messagesClone.concat(messagesResponse));
        } catch (e) {
            setError(e as string);
        } finally {
            setIsLoading(false);
        }


    }

    const resetChat = async () => {
        try {
            setMessages(storyCustomization.openTurns.map((turn: any) => {
                return {
                    owner: turn.owner,
                    content: turn.content,
                    createdAt: new Date()
                }
            }));
        } catch (e) {
            setError(e as string);
        }


    }

    const deleteLastChatUser = async () => {
        try {
            let index = messages.length - 1;
            while (index >= 0 && messages[index].owner !== userName) {
                index--;
            }
            if (index < 0) return;
            setMessages(messages.slice(0, index));
        } catch (e) {
            setError(e as string);
        }

    }

    const endOfMessagesRef = React.useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>
            <Card>
                <Card.Body style={{padding: "0 24px"}}>
                    <Dropdown className="float-end" align="end">
                        <Dropdown.Toggle
                            as="a"
                            className="cursor-pointer card-drop p-0 shadow-none"
                        >
                            <i className="mdi mdi-dots-vertical"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={resetChat}>Reset</Dropdown.Item>
                            <Dropdown.Item onClick={deleteLastChatUser}>Delete last chat User</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/*<h4 className="header-title mb-3">{episodeDetail.name}</h4>*/}

                    <div className="chat-conversation">
                        {/* chat messages */}
                        <SimpleBar style={{height: "50vh", width: "80%"}}>
                            <ul className={classNames("conversation-list", props.className)}>
                                {(messages || []).map((message, i) => {
                                    return (
                                        <ChatItem
                                            key={i}
                                            placement={
                                                message.owner === userName  ? "left" : "right"
                                            }
                                        >
                                            <ChatItemText
                                                userName={message.owner === userName ? "" : message.owner}
                                                text={message.content}
                                            />
                                        </ChatItem>
                                    );
                                })}
                                {isLoading && <ChatItem
                                    placement={"right"}
                                >
                                    <ChatItemText
                                        userName={""}
                                        text={"....."}
                                    />
                                </ChatItem>}
                                <div ref={endOfMessagesRef}/>
                            </ul>
                        </SimpleBar>

                        {/* chat form */}
                        <ChatForm onNewMessagesPosted={handleNewMessagePosted} disable={isLoading} onRegenNewMessagesPosted={handleRegenNewMessagePosted}/>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default ChatCustomList;
